<template>
    <div class="culturalRelics">
        <div class="top-wr">
            <img class="h-img" src="@/assets/imgs/ex1.png" alt="">
        </div>
        <div class="mid-wrapper">
            <div class="tab">
                <div
                    class="tab-item"
                    :class="{active: currentTab === item.classification}"
                    v-for="item in culturalRelicLists"
                    :key="item.classification"
                    @click="changeTab(item)">{{item.classificationName}}</div>
            </div>
        </div>
        <div class="con-box container">
            <div class="box-item" v-for="(n,i) in wwLists" :key="i" @click="intoDetail(n)">
                <img :src="n.cover" alt="" class="box-img">
                <div class="box-title">{{n.title}}</div>
            </div>
        </div>
        <div class="tc">
            <el-pagination
            background
            layout="prev, pager, next"
            class="tc"
            prev-text="上一页"
            next-text="下一页"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :total="total">
        </el-pagination>

        </div>
    </div>
</template>

<script setup>
import { ref,onMounted, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router/composables'
import {classification,antiqueLists} from '@/api/index'
const router = useRouter()
const tabList = [
    {
        classificationName:'铜钱',
        classification:1
    },
    {
        classificationName:'瓷器',
        classification:2
    },
    {
        classificationName:'青铜器',
        classification:3
    },
    {
        classificationName:'铜器',
        classification:4
    },
    {
        classificationName:'银器',
        classification:5
    },
    {
        classificationName:'其它',
        classification:6
    }
]
const currentTab = ref(1)
const changeTab = function (item) {
    currentTab.value = item.classification
    state.page = 1
    state.total = null
    state.wwLists = []
    getWwLists()
}
const intoDetail = function (item) {
    router.push('/cultural-relics/detail?id='+item.id)
}

const state =reactive({
    page:1,
    pageSize:10,
    wwLists:[],
    total:null,
    culturalRelicLists:tabList
})


// 获取文物分类
const getWwTypes = ()=>{
    classification().then(s=>{
        state.culturalRelicLists = s.data || []
        if(s.data.length > 0){
            currentTab.value = s.data[0].classification
            getWwLists()
        }
    })
}


// 获取文物列表
const getWwLists=()=>{
    antiqueLists({
      pageNo:state.page,
      pageSize:state.pageSize,
      classification:currentTab.value
    }).then(({data})=>{
        const _data = data?.list || []
        console.log(_data,999);

        state.wwLists = _data
        state.total=data.total
    })
}
// 分页页数更改的时候
const handleCurrentChange =(val)=>{
    state.page = val
    getWwLists()
}


const {wwLists,total,page,culturalRelicLists} = toRefs((state))


onMounted(() => {
  // getWwLists()
  getWwTypes()
})


</script>

<style lang="scss" scoped>
.culturalRelics{
    .top-wr{
        .h-img{
            width: 100%;
            height: 375px;
            font-size: 0;
            display: block;
            margin-top: -1px;
        }
    }
    .mid-wrapper{
        padding: 64px 0 95px;
        box-sizing: border-box;
        .tab{
            display: flex;
            justify-content: center;
            .tab-item{
                height:40px;
                padding: 0 20px;
                display: inline-block;
                margin: 0 19px;
                line-height: 40px;
                text-align: center;
                color:#414141;
                font-size:24px;
                cursor: pointer;
                background-image: url("~@/assets/imgs/ex3.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center;
                & + .tab-item{
                    margin-left: 33px;
                }
                &:hover{
                    background-image: url("~@/assets/imgs/ex4.png");
                    color:#8b3527;
                    cursor: pointer;
                }
                &.active{
                    background-image: url("~@/assets/imgs/ex2.png");
                    color:#fff;
                }

            }
        }
    }
    .con-box{
        width: 100%;
        display: grid;
        // 一排 5 个
        grid-template-columns: repeat(5, 1fr);
        grid-gap:  59px 30px;
        padding: 0 44px;
        box-sizing: border-box;

        .box-item{
            width: 100%;
            height: 444px;
            background-image: url("~@/assets/imgs/ex5.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .box-img{
                width:248.55px;
                height:234.83px;
                object-fit: cover;
                position: relative;
                top: 15px;
                transition: top 0.25s;
            }
            .box-title{
                color:#16161a;
                font-size:16px;
                margin-top: 53px;
            }
            &:hover{
                .box-img{
                    top: 0;
                }
            }
        }
    }
    .tc{
        padding: 67px 0 76px;
    }
}
</style>
